import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "/BitWiki.png",
        "alt": "BitWiki"
      }}></img></p>
    <h1>{`Welcome to BitWiki 🐱‍🏍 the Bitwig Studio Wiki`}</h1>
    <p>{`Welcome to the first comprehensive Bitwig Wiki. It is mainly maintained by the community
and does not claim to be complete. Here you should find all information about Bitwig.
Little tips and tricks or big guides and tutorials.`}</p>
    <h1>{`Meta`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "community"
        }}>{`Community`}</a>{` - list of Bitwig community resources`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "learning"
        }}>{`Learning`}</a>{` - resources for learning Bitwig Studio`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "tips-tricks"
        }}>{`Tips & Tricks`}</a>{` - some small things you probably missed`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "faq"
        }}>{`Frequently Asked Questions`}</a>{` - all the answers to usual questions`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "contributing"
        }}>{`Contributing`}</a>{` - how to contribute to this wiki`}</li>
    </ul>
    <h1>{`Bitwig Studio`}</h1>
    <ul>
      <li parentName="ul">{`Audio`}</li>
      <li parentName="ul">{`Automation`}</li>
      <li parentName="ul">{`Effects`}</li>
      <li parentName="ul">{`Grid`}</li>
      <li parentName="ul">{`Instruments`}</li>
      <li parentName="ul">{`Midi`}</li>
      <li parentName="ul">{`Modulation`}</li>
      <li parentName="ul">{`UI`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      